import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _095a038b = () => interopDefault(import('../pages/active/index.vue' /* webpackChunkName: "pages/active/index" */))
const _118e3a82 = () => interopDefault(import('../pages/bang-xep-hang/index.vue' /* webpackChunkName: "pages/bang-xep-hang/index" */))
const _1233d3a7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7ddceee8 = () => interopDefault(import('../pages/bo-de-old/index.vue' /* webpackChunkName: "pages/bo-de-old/index" */))
const _24465a47 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _4d7111ee = () => interopDefault(import('../pages/chat-ai/index.vue' /* webpackChunkName: "pages/chat-ai/index" */))
const _5d77325d = () => interopDefault(import('../pages/dang-ky-gia-su/index.vue' /* webpackChunkName: "pages/dang-ky-gia-su/index" */))
const _29454f30 = () => interopDefault(import('../pages/deeplink.vue' /* webpackChunkName: "pages/deeplink" */))
const _ed2e8020 = () => interopDefault(import('../pages/delete/index.vue' /* webpackChunkName: "pages/delete/index" */))
const _684d9292 = () => interopDefault(import('../pages/doi-mat-khau/index.vue' /* webpackChunkName: "pages/doi-mat-khau/index" */))
const _5749400d = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _2f3cab19 = () => interopDefault(import('../pages/gioi-thieu/index.vue' /* webpackChunkName: "pages/gioi-thieu/index" */))
const _09319ad2 = () => interopDefault(import('../pages/goi-cuoc/index.vue' /* webpackChunkName: "pages/goi-cuoc/index" */))
const _d18f8ea0 = () => interopDefault(import('../pages/hoi-bai/index.vue' /* webpackChunkName: "pages/hoi-bai/index" */))
const _3d7e7fd3 = () => interopDefault(import('../pages/hoi-bai-cua-toi/index.vue' /* webpackChunkName: "pages/hoi-bai-cua-toi/index" */))
const _67bf83f8 = () => interopDefault(import('../pages/huong-dan-cong-diem/index.vue' /* webpackChunkName: "pages/huong-dan-cong-diem/index" */))
const _f9ae5a10 = () => interopDefault(import('../pages/huong-dan-su-dung/index.vue' /* webpackChunkName: "pages/huong-dan-su-dung/index" */))
const _5bcbe886 = () => interopDefault(import('../pages/khoa-hoc-old/index.vue' /* webpackChunkName: "pages/khoa-hoc-old/index" */))
const _5960d99e = () => interopDefault(import('../pages/khong-ton-tai/index.vue' /* webpackChunkName: "pages/khong-ton-tai/index" */))
const _5617921a = () => interopDefault(import('../pages/lich-su-thanh-toan/index.vue' /* webpackChunkName: "pages/lich-su-thanh-toan/index" */))
const _620d1bfe = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2294ff1a = () => interopDefault(import('../pages/noi-quy-hoi-bai/index.vue' /* webpackChunkName: "pages/noi-quy-hoi-bai/index" */))
const _6d71533e = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _328354dd = () => interopDefault(import('../pages/tai-lieu-old/index.vue' /* webpackChunkName: "pages/tai-lieu-old/index" */))
const _48757bcc = () => interopDefault(import('../pages/thong-tin-ca-nhan/index.vue' /* webpackChunkName: "pages/thong-tin-ca-nhan/index" */))
const _b850c79e = () => interopDefault(import('../pages/thong-tin-khac/index.vue' /* webpackChunkName: "pages/thong-tin-khac/index" */))
const _66ef9e10 = () => interopDefault(import('../pages/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/index" */))
const _8596142c = () => interopDefault(import('../pages/thu-vien-cua-toi-old/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/index" */))
const _04371340 = () => interopDefault(import('../pages/tim-kiem/index.vue' /* webpackChunkName: "pages/tim-kiem/index" */))
const _25f016f5 = () => interopDefault(import('../pages/tu-hoc/index.vue' /* webpackChunkName: "pages/tu-hoc/index" */))
const _679ece9a = () => interopDefault(import('../pages/xac-thuc/index.vue' /* webpackChunkName: "pages/xac-thuc/index" */))
const _3ff50aba = () => interopDefault(import('../pages/blog/search.vue' /* webpackChunkName: "pages/blog/search" */))
const _6f33488e = () => interopDefault(import('../pages/bo-de-old/chuyen-de/index.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/index" */))
const _7e5b6d39 = () => interopDefault(import('../pages/bo-de-old/huong-dan.vue' /* webpackChunkName: "pages/bo-de-old/huong-dan" */))
const _95855018 = () => interopDefault(import('../pages/bo-de-old/index_old.vue' /* webpackChunkName: "pages/bo-de-old/index_old" */))
const _f424e9ba = () => interopDefault(import('../pages/event/invite/index.vue' /* webpackChunkName: "pages/event/invite/index" */))
const _1005af6b = () => interopDefault(import('../pages/gioi-thieu/mobile.vue' /* webpackChunkName: "pages/gioi-thieu/mobile" */))
const _708c928a = () => interopDefault(import('../pages/hoi-bai/index-old.vue' /* webpackChunkName: "pages/hoi-bai/index-old" */))
const _3afedaa5 = () => interopDefault(import('../pages/khoa-hoc-old/index_old.vue' /* webpackChunkName: "pages/khoa-hoc-old/index_old" */))
const _455e93c5 = () => interopDefault(import('../pages/tai-lieu-old/index_old.vue' /* webpackChunkName: "pages/tai-lieu-old/index_old" */))
const _63bcb1da = () => interopDefault(import('../pages/thu-vien/chuyen-de/index.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/index" */))
const _746313be = () => interopDefault(import('../pages/thu-vien/de-thi/index.vue' /* webpackChunkName: "pages/thu-vien/de-thi/index" */))
const _76cb3cd6 = () => interopDefault(import('../pages/thu-vien/khoa-hoc/index.vue' /* webpackChunkName: "pages/thu-vien/khoa-hoc/index" */))
const _0f45731d = () => interopDefault(import('../pages/thu-vien/search.vue' /* webpackChunkName: "pages/thu-vien/search" */))
const _508615f6 = () => interopDefault(import('../pages/thu-vien/tai-lieu/index.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/index" */))
const _074b2283 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi/index" */))
const _29c47dd4 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi-new/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi-new/index" */))
const _7174342e = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/da-lam" */))
const _40ef5cfd = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/tam-dung" */))
const _6e37fce4 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/dang-hoc" */))
const _e2ed4e90 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/theo-doi" */))
const _61d1e7ce = () => interopDefault(import('../pages/thu-vien-cua-toi-old/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/tai-lieu/da-xem" */))
const _73593548 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/da-lam" */))
const _5d987f97 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/tam-dung" */))
const _37d0564a = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/dang-hoc" */))
const _0514a518 = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/theo-doi" */))
const _0f55a0b4 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/dang-hoc" */))
const _560a1044 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/theo-doi" */))
const _47923cbf = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/da-xem" */))
const _7a2c634b = () => interopDefault(import('../pages/thu-vien/de-thi/guide.vue' /* webpackChunkName: "pages/thu-vien/de-thi/guide" */))
const _0bce5ac2 = () => interopDefault(import('../pages/thu-vien/de-thi/lam-bai/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/lam-bai/_slug" */))
const _e6b365ac = () => interopDefault(import('../pages/thu-vien/de-thi/xem/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/xem/_slug" */))
const _5396f24a = () => interopDefault(import('../pages/blog/danh-muc/_slug.vue' /* webpackChunkName: "pages/blog/danh-muc/_slug" */))
const _72a08b1e = () => interopDefault(import('../pages/bo-de-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/_slug" */))
const _61ca823f = () => interopDefault(import('../pages/bo-de-old/ket-qua/_slug.vue' /* webpackChunkName: "pages/bo-de-old/ket-qua/_slug" */))
const _6a776bec = () => interopDefault(import('../pages/bo-de-old/lam-bai/_slug.vue' /* webpackChunkName: "pages/bo-de-old/lam-bai/_slug" */))
const _4d51bf0d = () => interopDefault(import('../pages/khoa-hoc-old/chi-tiet/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chi-tiet/_slug" */))
const _9612973c = () => interopDefault(import('../pages/khoa-hoc-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chuyen-de/_slug" */))
const _39af1582 = () => interopDefault(import('../pages/tai-lieu-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/chuyen-de/_slug" */))
const _390453d8 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/_slug" */))
const _51e5139e = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/_slug" */))
const _9e6f615e = () => interopDefault(import('../pages/thu-vien-cua-toi-old/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/tai-lieu/_slug" */))
const _6a716c8c = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/_slug" */))
const _90b88a16 = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/_slug" */))
const _1892ce6a = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/_slug" */))
const _651d1c2a = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/_slug" */))
const _62061092 = () => interopDefault(import('../pages/thu-vien/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/_slug" */))
const _77d0564e = () => interopDefault(import('../pages/thu-vien/de-thi/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/_slug" */))
const _75149b8e = () => interopDefault(import('../pages/thu-vien/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien/khoa-hoc/_slug" */))
const _191f0fc8 = () => interopDefault(import('../pages/thu-vien/live/_slug.vue' /* webpackChunkName: "pages/thu-vien/live/_slug" */))
const _4ecf74ae = () => interopDefault(import('../pages/thu-vien/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/_slug" */))
const _23ff8fd1 = () => interopDefault(import('../pages/thu-vien/video/_slug.vue' /* webpackChunkName: "pages/thu-vien/video/_slug" */))
const _107d325f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _814a3178 = () => interopDefault(import('../pages/bo-de-old/_slug.vue' /* webpackChunkName: "pages/bo-de-old/_slug" */))
const _47575258 = () => interopDefault(import('../pages/cau-hoi-cong-dong/_slug.vue' /* webpackChunkName: "pages/cau-hoi-cong-dong/_slug" */))
const _356f6716 = () => interopDefault(import('../pages/cau-hoi/_slug.vue' /* webpackChunkName: "pages/cau-hoi/_slug" */))
const _3bc7de8b = () => interopDefault(import('../pages/hoi-bai-cua-toi/_slug.vue' /* webpackChunkName: "pages/hoi-bai-cua-toi/_slug" */))
const _5f392b16 = () => interopDefault(import('../pages/khoa-hoc-old/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/_slug" */))
const _30ccb395 = () => interopDefault(import('../pages/tai-lieu-old/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/_slug" */))
const _931acac2 = () => interopDefault(import('../pages/tra-loi/_slug-old.vue' /* webpackChunkName: "pages/tra-loi/_slug-old" */))
const _1fb0d976 = () => interopDefault(import('../pages/tra-loi/_slug.vue' /* webpackChunkName: "pages/tra-loi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/active",
    component: _095a038b,
    name: "active"
  }, {
    path: "/bang-xep-hang",
    component: _118e3a82,
    name: "bang-xep-hang"
  }, {
    path: "/blog",
    component: _1233d3a7,
    name: "blog"
  }, {
    path: "/bo-de-old",
    component: _7ddceee8,
    name: "bo-de-old"
  }, {
    path: "/callback",
    component: _24465a47,
    name: "callback"
  }, {
    path: "/chat-ai",
    component: _4d7111ee,
    name: "chat-ai"
  }, {
    path: "/dang-ky-gia-su",
    component: _5d77325d,
    name: "dang-ky-gia-su"
  }, {
    path: "/deeplink",
    component: _29454f30,
    name: "deeplink"
  }, {
    path: "/delete",
    component: _ed2e8020,
    name: "delete"
  }, {
    path: "/doi-mat-khau",
    component: _684d9292,
    name: "doi-mat-khau"
  }, {
    path: "/download",
    component: _5749400d,
    name: "download"
  }, {
    path: "/gioi-thieu",
    component: _2f3cab19,
    name: "gioi-thieu"
  }, {
    path: "/goi-cuoc",
    component: _09319ad2,
    name: "goi-cuoc"
  }, {
    path: "/hoi-bai",
    component: _d18f8ea0,
    name: "hoi-bai"
  }, {
    path: "/hoi-bai-cua-toi",
    component: _3d7e7fd3,
    name: "hoi-bai-cua-toi"
  }, {
    path: "/huong-dan-cong-diem",
    component: _67bf83f8,
    name: "huong-dan-cong-diem"
  }, {
    path: "/huong-dan-su-dung",
    component: _f9ae5a10,
    name: "huong-dan-su-dung"
  }, {
    path: "/khoa-hoc-old",
    component: _5bcbe886,
    name: "khoa-hoc-old"
  }, {
    path: "/khong-ton-tai",
    component: _5960d99e,
    name: "khong-ton-tai"
  }, {
    path: "/lich-su-thanh-toan",
    component: _5617921a,
    name: "lich-su-thanh-toan"
  }, {
    path: "/maintenance",
    component: _620d1bfe,
    name: "maintenance"
  }, {
    path: "/noi-quy-hoi-bai",
    component: _2294ff1a,
    name: "noi-quy-hoi-bai"
  }, {
    path: "/redirect",
    component: _6d71533e,
    name: "redirect"
  }, {
    path: "/tai-lieu-old",
    component: _328354dd,
    name: "tai-lieu-old"
  }, {
    path: "/thong-tin-ca-nhan",
    component: _48757bcc,
    name: "thong-tin-ca-nhan"
  }, {
    path: "/thong-tin-khac",
    component: _b850c79e,
    name: "thong-tin-khac"
  }, {
    path: "/thu-vien-cua-toi",
    component: _66ef9e10,
    name: "thu-vien-cua-toi"
  }, {
    path: "/thu-vien-cua-toi-old",
    component: _8596142c,
    name: "thu-vien-cua-toi-old"
  }, {
    path: "/tim-kiem",
    component: _04371340,
    name: "tim-kiem"
  }, {
    path: "/tu-hoc",
    component: _25f016f5,
    name: "tu-hoc"
  }, {
    path: "/xac-thuc",
    component: _679ece9a,
    name: "xac-thuc"
  }, {
    path: "/blog/search",
    component: _3ff50aba,
    name: "blog-search"
  }, {
    path: "/bo-de-old/chuyen-de",
    component: _6f33488e,
    name: "bo-de-old-chuyen-de"
  }, {
    path: "/bo-de-old/huong-dan",
    component: _7e5b6d39,
    name: "bo-de-old-huong-dan"
  }, {
    path: "/bo-de-old/index_old",
    component: _95855018,
    name: "bo-de-old-index_old"
  }, {
    path: "/event/invite",
    component: _f424e9ba,
    name: "event-invite"
  }, {
    path: "/gioi-thieu/mobile",
    component: _1005af6b,
    name: "gioi-thieu-mobile"
  }, {
    path: "/hoi-bai/index-old",
    component: _708c928a,
    name: "hoi-bai-index-old"
  }, {
    path: "/khoa-hoc-old/index_old",
    component: _3afedaa5,
    name: "khoa-hoc-old-index_old"
  }, {
    path: "/tai-lieu-old/index_old",
    component: _455e93c5,
    name: "tai-lieu-old-index_old"
  }, {
    path: "/thu-vien/chuyen-de",
    component: _63bcb1da,
    name: "thu-vien-chuyen-de"
  }, {
    path: "/thu-vien/de-thi",
    component: _746313be,
    name: "thu-vien-de-thi"
  }, {
    path: "/thu-vien/khoa-hoc",
    component: _76cb3cd6,
    name: "thu-vien-khoa-hoc"
  }, {
    path: "/thu-vien/search",
    component: _0f45731d,
    name: "thu-vien-search"
  }, {
    path: "/thu-vien/tai-lieu",
    component: _508615f6,
    name: "thu-vien-tai-lieu"
  }, {
    path: "/thu-vien/thu-vien-cua-toi",
    component: _074b2283,
    name: "thu-vien-thu-vien-cua-toi"
  }, {
    path: "/thu-vien/thu-vien-cua-toi-new",
    component: _29c47dd4,
    name: "thu-vien-thu-vien-cua-toi-new"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/da-lam",
    component: _7174342e,
    name: "thu-vien-cua-toi-old-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/tam-dung",
    component: _40ef5cfd,
    name: "thu-vien-cua-toi-old-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/dang-hoc",
    component: _6e37fce4,
    name: "thu-vien-cua-toi-old-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/theo-doi",
    component: _e2ed4e90,
    name: "thu-vien-cua-toi-old-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi-old/tai-lieu/da-xem",
    component: _61d1e7ce,
    name: "thu-vien-cua-toi-old-tai-lieu-da-xem"
  }, {
    path: "/thu-vien-cua-toi/bo-de/da-lam",
    component: _73593548,
    name: "thu-vien-cua-toi-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi/bo-de/tam-dung",
    component: _5d987f97,
    name: "thu-vien-cua-toi-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/dang-hoc",
    component: _37d0564a,
    name: "thu-vien-cua-toi-chuyen-de-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/theo-doi",
    component: _0514a518,
    name: "thu-vien-cua-toi-chuyen-de-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/dang-hoc",
    component: _0f55a0b4,
    name: "thu-vien-cua-toi-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/theo-doi",
    component: _560a1044,
    name: "thu-vien-cua-toi-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/da-xem",
    component: _47923cbf,
    name: "thu-vien-cua-toi-tai-lieu-da-xem"
  }, {
    path: "/thu-vien/de-thi/guide",
    component: _7a2c634b,
    name: "thu-vien-de-thi-guide"
  }, {
    path: "/thu-vien/de-thi/lam-bai/:slug?",
    component: _0bce5ac2,
    name: "thu-vien-de-thi-lam-bai-slug"
  }, {
    path: "/thu-vien/de-thi/xem/:slug?",
    component: _e6b365ac,
    name: "thu-vien-de-thi-xem-slug"
  }, {
    path: "/blog/danh-muc/:slug?",
    component: _5396f24a,
    name: "blog-danh-muc-slug"
  }, {
    path: "/bo-de-old/chuyen-de/:slug?",
    component: _72a08b1e,
    name: "bo-de-old-chuyen-de-slug"
  }, {
    path: "/bo-de-old/ket-qua/:slug?",
    component: _61ca823f,
    name: "bo-de-old-ket-qua-slug"
  }, {
    path: "/bo-de-old/lam-bai/:slug?",
    component: _6a776bec,
    name: "bo-de-old-lam-bai-slug"
  }, {
    path: "/khoa-hoc-old/chi-tiet/:slug?",
    component: _4d51bf0d,
    name: "khoa-hoc-old-chi-tiet-slug"
  }, {
    path: "/khoa-hoc-old/chuyen-de/:slug?",
    component: _9612973c,
    name: "khoa-hoc-old-chuyen-de-slug"
  }, {
    path: "/tai-lieu-old/chuyen-de/:slug?",
    component: _39af1582,
    name: "tai-lieu-old-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/:slug?",
    component: _390453d8,
    name: "thu-vien-cua-toi-old-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/:slug?",
    component: _51e5139e,
    name: "thu-vien-cua-toi-old-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi-old/tai-lieu/:slug?",
    component: _9e6f615e,
    name: "thu-vien-cua-toi-old-tai-lieu-slug"
  }, {
    path: "/thu-vien-cua-toi/bo-de/:slug?",
    component: _6a716c8c,
    name: "thu-vien-cua-toi-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/:slug?",
    component: _90b88a16,
    name: "thu-vien-cua-toi-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/:slug?",
    component: _1892ce6a,
    name: "thu-vien-cua-toi-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/:slug?",
    component: _651d1c2a,
    name: "thu-vien-cua-toi-tai-lieu-slug"
  }, {
    path: "/thu-vien/chuyen-de/:slug?",
    component: _62061092,
    name: "thu-vien-chuyen-de-slug"
  }, {
    path: "/thu-vien/de-thi/:slug?",
    component: _77d0564e,
    name: "thu-vien-de-thi-slug"
  }, {
    path: "/thu-vien/khoa-hoc/:slug?",
    component: _75149b8e,
    name: "thu-vien-khoa-hoc-slug"
  }, {
    path: "/thu-vien/live/:slug?",
    component: _191f0fc8,
    name: "thu-vien-live-slug"
  }, {
    path: "/thu-vien/tai-lieu/:slug?",
    component: _4ecf74ae,
    name: "thu-vien-tai-lieu-slug"
  }, {
    path: "/thu-vien/video/:slug?",
    component: _23ff8fd1,
    name: "thu-vien-video-slug"
  }, {
    path: "/blog/:slug",
    component: _107d325f,
    name: "blog-slug"
  }, {
    path: "/bo-de-old/:slug?",
    component: _814a3178,
    name: "bo-de-old-slug"
  }, {
    path: "/cau-hoi-cong-dong/:slug?",
    component: _47575258,
    name: "cau-hoi-cong-dong-slug"
  }, {
    path: "/cau-hoi/:slug?",
    component: _356f6716,
    name: "cau-hoi-slug"
  }, {
    path: "/hoi-bai-cua-toi/:slug?",
    component: _3bc7de8b,
    name: "hoi-bai-cua-toi-slug"
  }, {
    path: "/khoa-hoc-old/:slug?",
    component: _5f392b16,
    name: "khoa-hoc-old-slug"
  }, {
    path: "/tai-lieu-old/:slug?",
    component: _30ccb395,
    name: "tai-lieu-old-slug"
  }, {
    path: "/tra-loi/:slug-old?",
    component: _931acac2,
    name: "tra-loi-slug-old"
  }, {
    path: "/tra-loi/:slug?",
    component: _1fb0d976,
    name: "tra-loi-slug"
  }, {
    path: "/",
    component: _d18f8ea0,
    name: "index"
  }, {
    path: "/blog-app",
    component: _1233d3a7,
    name: "blog-app"
  }, {
    path: "/blog-app/search",
    component: _3ff50aba,
    name: "blog-app-search"
  }, {
    path: "/blog-app/:slug",
    component: _107d325f,
    name: "blog-app-slug"
  }, {
    path: "/blog-app/category/:slug",
    component: _5396f24a,
    name: "blog-app-category-slug"
  }, {
    path: "/hoi-bai/:slug",
    component: _d18f8ea0,
    name: "hoi-bai-slug"
  }, {
    path: "/thu-vien",
    component: _76cb3cd6,
    name: "thu-vien"
  }, {
    path: "/library/quiz/guide",
    component: _7a2c634b,
    name: "quiz-guide"
  }, {
    path: "/khoa-hoc",
    component: _76cb3cd6,
    name: "khoa-hoc"
  }, {
    path: "/khoa-hoc/:slug",
    component: _75149b8e,
    name: "khoa-hoc-slug"
  }, {
    path: "/video/:slug",
    component: _23ff8fd1,
    name: "video-slug"
  }, {
    path: "/tai-lieu",
    component: _508615f6,
    name: "tai-lieu"
  }, {
    path: "/tai-lieu/:slug",
    component: _4ecf74ae,
    name: "tai-lieu-slug"
  }, {
    path: "/trac-nghiem",
    component: _746313be,
    name: "trac-nghiem"
  }, {
    path: "/trac-nghiem/:slug",
    component: _77d0564e,
    name: "trac-nghiem-slug"
  }, {
    path: "/trac-nghiem/lam-bai/:slug",
    component: _0bce5ac2,
    name: "trac-nghiem-lam-bai"
  }, {
    path: "/trac-nghiem/ket-qua/:slug",
    component: _e6b365ac,
    name: "trac-nghiem-ket-qua"
  }, {
    path: "/co-che-cong-diem",
    component: _67bf83f8,
    name: "co-che-cong-diem"
  }, {
    path: "*",
    component: _5960d99e
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
