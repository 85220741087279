const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['is-mobile'] = require('../middleware/is-mobile.js')
middleware['is-mobile'] = middleware['is-mobile'].default || middleware['is-mobile']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['route'] = require('../middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

middleware['unauthenticated'] = require('../middleware/unauthenticated.js')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
